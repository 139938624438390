import { createStore, useStore as baseUseStore, Store } from "vuex";
import { InjectionKey } from "vue";
import {
  OrderExt,
  UserState,
  RuntimeConfigExt,
  NfzState,
  AppState,
  PopupState,
} from "../../types";

export interface State {
  user: UserState;
  order: OrderExt;
  runtimeConfig: RuntimeConfigExt;
  nfz: NfzState;
  appState: AppState;
  popupState: PopupState;
}

export const key: InjectionKey<Store<State>> = Symbol();

export const initialUserState: UserState = {
  roles: [],
  consentsVersionChanged: false,
  consentsVersion: null,
  termsAgreement: false,
  marketingAgreement: false,
  commercialAgreement: false,
  showSubscriptionEndPopup: false,
};
export const initialOrderState: OrderExt = {
  id: 0,
  doctor: {
    id: 0,
    firstName: "",
    lastName: "",
    specializations: [{ id: 0, name: "" }],
  },
  start: new Date().toISOString(),
  end: new Date().toISOString(),
  price: 0,
  specializationId: 0,
  country: { id: 0, name: "" },
  adultsCount: 0,
  childrenCount: 0,
  patients: [],
  appointmentType: "CALL",
  address: {
    city: "",
    postCode: "",
    streetName: "",
    streetNumber: "",
    country: "",
    flatNumber: "",
    additionalInfo: "",
  },
  email: "",
  phoneNumber: {
    number: "",
    prefix: "",
    pattern: "",
  },
  setDefaultAddress: false,
  forNfz: false,
};

export const initialNfzState: NfzState = {
  nfzActive: false,
};

export const store = createStore<State>({
  state: {
    user: initialUserState,
    order: JSON.parse(
      sessionStorage.getItem("order") || JSON.stringify(initialOrderState)
    ),
    runtimeConfig: {
      apiUrl: "/",
      authUrl: "",
      siteUrl: "",
      type: "GENERAL",
      recapthaSiteKey: "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI",
      thankuPage: "",
      mockup: true,
      mode: "development",
      maintenance: false,
      allowedIframeDataSources: [],
      kcAuthRealm: "",
      kcAuthClientId: "",
      kcAuthUrl: "",
    },
    nfz: JSON.parse(
      sessionStorage.getItem("nfz") || JSON.stringify(initialNfzState)
    ),
    appState: {
      isLoading: true,
    },
    popupState: {
      makeDeclaration: false,
      changeDeclarationFacility: false,
      subscriptionEnd: false,
    },
  },
  getters: {
    consentsVersionChanged(state) {
      return state.user.consentsVersionChanged;
    },
    consentsVersion(state) {
      return state.user.consentsVersion;
    },
    acceptedTermsAgreement(state) {
      return state.user.termsAgreement;
    },
    acceptedMarketingAgreement(state) {
      return state.user.marketingAgreement;
    },
    acceptedCommercialAgreement(state) {
      return state.user.commercialAgreement;
    },
    showSubscriptionEndPopup(state) {
      return state.user.showSubscriptionEndPopup;
    },
    isNfzActive(state) {
      return state.nfz.nfzActive;
    },
    isForNfz(state) {
      return state.order.forNfz;
    },
    getLoadingStatus(state) {
      return state.appState.isLoading;
    },
    getPopupStatus(state) {
      return state.popupState;
    },
  },
  mutations: {
    updateUser(state, data: Partial<UserState>) {
      state.user = { ...state.user, ...data };
      sessionStorage.setItem("user", JSON.stringify(state.user));
    },
    setUser(state, data: UserState) {
      state.user = data;
      sessionStorage.setItem("user", JSON.stringify(data));
    },
    setOrder(state, data: OrderExt) {
      state.order = data;
      sessionStorage.setItem("order", JSON.stringify(data));
    },
    setRuntimeConfig(state, data: RuntimeConfigExt) {
      state.runtimeConfig = data;
    },
    updateRuntimeConfig(state, data: RuntimeConfigExt) {
      state.runtimeConfig = { ...state.runtimeConfig, ...data };
    },
    clearOrder(state) {
      state.order = { ...initialOrderState };
      sessionStorage.setItem("order", JSON.stringify(state.order));
    },
    resetUser(state) {
      state.user = initialUserState;
      state.nfz = initialNfzState;
      sessionStorage.setItem("user", JSON.stringify(state.user));
      sessionStorage.removeItem("nfz");
    },
    setNfzActive(state, data: NfzState["nfzActive"]) {
      state.nfz.nfzActive = data;
      sessionStorage.setItem("nfz", JSON.stringify(state.nfz));
    },
    setConsentsVersion(state, data: UserState["consentsVersion"]) {
      state.user.consentsVersion = data;
    },
    setConsentsVersionChanged(
      state,
      data: UserState["consentsVersionChanged"]
    ) {
      state.user.consentsVersionChanged = data;
    },
    setUserTermsAgreement(state, data: UserState["termsAgreement"]) {
      state.user.termsAgreement = data;
    },
    setUserMarketingAgreement(state, data: UserState["marketingAgreement"]) {
      state.user.marketingAgreement = data;
    },
    setUserCommercialAgreement(state, data: UserState["commercialAgreement"]) {
      state.user.commercialAgreement = data;
    },
    setUserShowSubscriptionEndPopup(
      state,
      data: UserState["showSubscriptionEndPopup"]
    ) {
      state.user.showSubscriptionEndPopup = data;
    },
    setForNfz(state, data: OrderExt["forNfz"]) {
      state.order.forNfz = data;
      sessionStorage.setItem("order", JSON.stringify(state.order));
    },
    setLodingStatus(state, data: AppState["isLoading"]) {
      state.appState.isLoading = data;
    },
    setMakeDeclarationPopupStatus(state, data: PopupState["makeDeclaration"]) {
      state.popupState.makeDeclaration = data;
    },
    setChangeDeclarationFacilityStatus(
      state,
      data: PopupState["changeDeclarationFacility"]
    ) {
      state.popupState.changeDeclarationFacility = data;
    },
  },
});

export function useStore() {
  return baseUseStore(key);
}
